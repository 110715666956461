import React, { memo, useContext } from 'react';

import PropTypes from 'prop-types';
import { Panel, Grid, GridItem, Label, Flex, Caption } from '@sdflc/ui';

import { PageLoading, Fulfillment } from '../../components';
import { withProviders } from '../../hoc';
import { SquareUpContext } from '../../contexts';

const Queue = memo((props) => {
  const { firstTimeLoading, ordersProposed } = useContext(SquareUpContext);

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  return <Fulfillment ordersProposed={ordersProposed} firstTimeLoading={firstTimeLoading} />;
});

Queue.displayName = 'Queue';

Queue.propTypes = {};

Queue.defaultProps = {};

export default withProviders(Queue);
